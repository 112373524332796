import React from "react";

export class DGDGFormInputComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            keyCode: null,
        }
        this.onBlur = this.onBlur.bind(this);
        this.onTextChange = this.onTextChange.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onClearClick = this.onClearClick.bind(this);
        this.onLookupClick = this.onLookupClick.bind(this);
        this.onMaskClick = this.onMaskClick.bind(this);
    }

    onBlur(event) {
        if (this.props.onBlur) {
            this.props.onBlur(event, this.props.id, event.target.value, this.props.additionalData);
        }
    }

    onTextChange(event) {
        if (this.state.keyCode === 8) {
            if (this.props.onChange) {
                this.props.onChange(this.props.id, event.target.value, this.props.additionalData);
            }
        }

        else {
            switch (this.props.inputType) {
                case "money":
                case "decimal":
                    const regexDecimal_2 = /^-{0,1}(\d|,)*(\.)?(\d{1,2})?$/;
                    if (event.target.value === '' || regexDecimal_2.test(event.target.value)) {
                        if (this.props.onChange) {
                            this.props.onChange(this.props.id, event.target.value, this.props.additionalData);
                        }
                    }
                    break;

                case "percent":
                case "percent_2":
                    const regexPercent_2 = /^(\d|,)*(\.)?(\d{1,2})?$/;
                    if (event.target.value === '' || regexPercent_2.test(event.target.value)) {
                        if (this.props.onChange) {
                            this.props.onChange(this.props.id, event.target.value, this.props.additionalData);
                        }
                    }
                    break;


                case "percent_1":
                    const regexDecimal_1 = /^-{0,1}(\d|,)*(\.)?(\d)?$/;
                    if (event.target.value === '' || regexDecimal_1.test(event.target.value)) {
                        if (this.props.onChange) {
                            this.props.onChange(this.props.id, event.target.value, this.props.additionalData);
                        }
                    }
                    break;

                case "number":
                    const regexNumber = /^(\d|,)*$/;
                    if (event.target.value === '' || regexNumber.test(event.target.value)) {
                        if (this.props.onChange) {
                            this.props.onChange(this.props.id, event.target.value, this.props.additionalData);
                        }
                    }
                    break;

                default:
                    if (this.props.onChange) {
                        this.props.onChange(this.props.id, event.target.value, this.props.additionalData);
                    }
                    break;
            }
        }
    }

    onKeyDown(event) {
        this.setState({ keyCode: event.keyCode })
        if (this.props.enterToTab && event.keyCode === 13) {
            event.preventDefault();
            const inputs = Array.from(document.querySelectorAll("input")); //Get array of inputs
            let nextInput = inputs[inputs.indexOf(document.activeElement) + 1];//get index of input after the current input
            nextInput.focus(); //focus new input
        }
        else if (this.props.onLookupClick && event.keyCode === 13) {
            this.props.onLookupClick(this.props.id, this.props.value, this.props.additionalData);
        }
        else if (this.props.onKeyDown) {
            this.props.onKeyDown(event, this.props.additionalData);
        }
    }

    onClearClick(event) {
        if (this.props.onChange) {
            this.props.onChange(this.props.id, "", this.props.additionalData);
        }
    }

    onLookupClick(event) {
        if (this.props.onChange) {
            this.props.onLookupClick(this.props.id, this.props.value, this.props.additionalData);
        }
    }

    onMaskClick(event, masked) {
        if (this.props.onChange) {
            this.props.onMaskClick(event, masked);
        }
    }

    render() {
        let colSpan = this.props.colSpan ? this.props.colSpan : "col-form-label";
        let labelSpan = this.props.labelSpan ? this.props.labelSpan : "col-form-label dgdg-form-label";
        let controlSpan = this.props.controlSpan ? this.props.controlSpan : "col-form-label";
        let placeHolder = this.props.placeholder ? this.props.placeholder : this.props.label;
        if (this.props.cssName) {
            colSpan += " " + this.props.cssName;
        }
        if (this.props.labelCssName) {
            labelSpan += " " + this.props.labelCssName;
        }
        if (this.props.controlCssName) {
            controlSpan += " " + this.props.controlCssName;
        }

        let value = (this.props.value !== null && this.props.value !== undefined) ? this.props.value : "";
        if (value && (this.props.inputType === "money"
            || this.props.inputType === "decimal"
            || this.props.inputType === "percent" || this.props.inputType === "percent_1" || this.props.inputType === "percent_2")) {

            value = value.toString().replace("(", "-").replace(")", "");
        }

        return <div className={"form-group " + colSpan}>
            {
                this.props.label
                    ? <label className={"dgdg-font-blue " + labelSpan} htmlFor={this.props.id}>
                        {this.props.label}:
                        {
                            this.props.isRequired
                                ? <span className="text-danger">*</span>
                                : null
                        }
                    </label>
                    : null
            }
            <div className={"input-group " + controlSpan}>
                {
                    (this.props.inputType === "money")
                        ? <div className="input-group-prepend">
                            <span className="input-group-text">$</span>
                        </div>
                        : null
                }
                {
                    this.props.type === "password"
                        ? <input type="password" style={{ "-webkit-text-security": "square" }} id={this.props.id} disabled={this.props.disabled} readOnly={this.props.readOnly} className={"form-control " + (this.props.inputCssName ? this.props.inputCssName : "")} maxLength={this.props.maxLength} placeholder={placeHolder} value={value} onChange={(event) => this.onTextChange(event)} onKeyDown={(event) => this.onKeyDown(event)} onBlur={(event) => this.onBlur(event)} />
                        : <input id={this.props.id} disabled={this.props.disabled} hidden={this.props.isHidden} readOnly={this.props.readOnly} className={"form-control " + (this.props.inputCssName ? this.props.inputCssName : "")} maxLength={this.props.maxLength} placeholder={placeHolder} value={value} onChange={(event) => this.onTextChange(event)} onKeyDown={(event) => this.onKeyDown(event)} onBlur={(event) => this.onBlur(event)} />
                }
                {
                    (this.props.inputType === "percent" || this.props.inputType === "percent_1" || this.props.inputType === "percent_2")
                        ? <div className="input-group-append">
                            <span className="input-group-text">%</span>
                        </div>
                        : null
                }
                {
                    (this.props.onLookupClick || this.props.hideClear !== true)
                        ? <span className="input-group-append">
                            {
                                (this.props.onLookupClick)
                                    ? <button className="btn btn-secondary" type="button" disabled={this.props.disabled || this.props.readOnly} onClick={(event) => this.onLookupClick(event)}>
                                        <i className="far fa-search" />
                                    </button>
                                    : null
                            }
                            {
                                (this.props.onMaskClick)
                                    ? <button className="btn btn-secondary" type="button" disabled={this.props.disabled || this.props.readOnly} onClick={(event) => this.onMaskClick(event, this.props.masked)}>
                                        {
                                            this.props.masked
                                                ? <i class="fa-solid fa-eye"></i>
                                                : <i class="fa-solid fa-eye-slash"></i>
                                        }
                                    </button>
                                    : null
                            }
                            {
                                (this.props.hideClear !== true)
                                    ? <button className="btn btn-secondary" type="button" disabled={this.props.disabled || this.props.readOnly} onClick={(event) => this.onClearClick(event)} tabIndex="-1">
                                        <span className="far fa-times" />
                                    </button>
                                    : null
                            }
                        </span>
                        : null
                }
            </div>
        </div>;
    }
}
