import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGFormInputComponent } from "../common/dgdginput/DGDGFormInputComponent";
import { DGDGFormDropdownComponent } from "../common/dgdgdropdown/DGDGFormDropdownComponent";
import { DGDGModalDialogComponent } from "../common/dgdgmodaldialog/DGDGModalDialogComponent";
import { DGDGModalDialogHeaderButtonComponent } from "../common/dgdgmodaldialog/DGDGModalDialogHeaderButtonComponent";

export class DealIssuesAddComponent extends React.Component {

    static getDerivedStateFromProps(props, state) {
        let newState = {};
        try {
            console.devLog("DealIssuesAddComponent - getDerivedStateFromProps");
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
        return newState;
    }

    constructor(props) {
        super(props);
        this.state = {
            showSpinner: false,
            id: null,
            dealNumber: "",
            isSaveEnabled: false,
            customerName: "",
            stockNumber: "",
            vin: "",
            make: "",
            model: "",
            year: "",
            salesDetails: null,
            issue: "",
            amount: "",
            status: null,
            lastUpdatedBy: this.props.lastUpdatedBy,
            selectedType: null,
            selectedStore: null,
            dialogTitle: "Add Deal Issue"
        };

        this.onStoreClick = this.onStoreClick.bind(this);
        this.onDealNumberChange = this.onDealNumberChange.bind(this);
        this.onLookupClick = this.onLookupClick.bind(this);
        this.onTypeClick = this.onTypeClick.bind(this);
        this.onTextChange = this.onTextChange.bind(this);
        this.onSaveNewClick = this.onSaveNewClick.bind(this);
        this.onSaveCloseClick = this.onSaveCloseClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if (this.props.rowData !== null && this.props.rowData !== prevProps.rowData) {
                let selectedStore = this.props.stores.filter(storeRow => {
                    return storeRow.store_number === this.props.rowData.store_number;
                });
                let selectedType = this.typeList.filter(typeRow => {
                    return typeRow.value === this.props.rowData.type;
                });
                let dialogTitle = "Edit Deal Issue - " + this.props.rowData.customer_name + ", " + this.props.rowData.store_number + " - " + this.props.rowData.deal_number;
                this.setState({
                    id: this.props.rowData.id,
                    selectedStore: selectedStore[0],
                    dealNumber: this.props.rowData.deal_number,
                    customerName: this.props.rowData.customer_name,
                    selectedType: selectedType[0],
                    stockNumber: this.props.rowData.stock_number,
                    vin: this.props.rowData.vin,
                    make: this.props.rowData.make,
                    year: this.props.rowData.year,
                    model: this.props.rowData.model,
                    issue: this.props.rowData.issue,
                    amount: this.props.rowData.amount,
                    lastUpdatedBy: this.props.rowData.last_updated_by,
                    status: this.props.rowData.status,
                    isSaveEnabled: true,
                    dialogTitle: dialogTitle
                });
            }
            else if ((this.props.selectedStore !== prevProps.selectedStore) || this.state.selectedStore === null) {
                let selectedStore = this.props.selectedStore?.store_number === null ? this.props.stores[1] : this.props.selectedStore;
                if (selectedStore !== this.state.selectedStore) {
                    this.setState({ selectedStore: selectedStore })
                }
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Add Exception Deal";
    typeList = [
        { key: "Deal", value: "Deal" },
        { key: "Incentive", value: "Incentive" },
        { key: "DMV Service", value: "DMV Service" },
        { key: "DMV Finance", value: "DMV Finance" },
        { key: "Aftermarket", value: "Aftermarket" },
        { key: "Title", value: "Title" },
        { key: "Other", value: "Other" }
    ];

    onStoreClick(id, store) {
        try {
            this.setState({
                selectedStore: store
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onLookupClick(id, value) {
        try {
            this.setState({
                [id]: value
            }, this.getSalesDetails);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDealNumberChange(id, value) {
        try {
            this.setState({
                [id]: value
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onTextChange(id, value) {
        try {
            this.setState({
                [id]: value
            }, this.validateForm);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onTypeClick(event, type) {
        try {
            this.setState({
                selectedType: type
            }, this.validateForm);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onSaveNewClick(event) {
        this.onSaveClick(event);
        this.setState({
            dealNumber: "",
            selectedType: "",
            customerName: "",
            stockNumber: "",
            vin: "",
            make: "",
            year: "",
            model: "",
            issue: "",
            amount: "",
            lastUpdatedBy: this.props.lastUpdatedBy,
            isSaveEnabled: false,
            status: null
        });
    }

    onSaveCloseClick(event) {
        this.onSaveClick(event);
        this.onCancelClick(event);
    }

    onSaveClick(event) {
        try {
            if (!this.props.rowData) {
                this.props.onSaveClick(event, {
                    id: this.state.id,
                    storeNumber: this.state.selectedStore.store_number,
                    dealNumber: this.state.dealNumber,
                    type: this.state.selectedType?.key,
                    issue: this.state.issue,
                    status: this.state.status,
                    amount: this.state.amount
                });
            }
            else {
                this.props.onEditDealIssueClick(event, {
                    storeNumber: this.state.selectedStore.store_number,
                    dealNumber: this.state.dealNumber,
                    type: this.state.selectedType?.key,
                    issue: this.state.issue,
                    amount: this.state.amount
                });
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCancelClick(event) {
        try {
            this.setState({
                dealNumber: "",
                selectedStore: null,
                selectedType: "",
                customerName: "",
                stockNumber: "",
                vin: "",
                make: "",
                year: "",
                model: "",
                issue: "",
                amount: "",
                lastUpdatedBy: this.props.lastUpdatedBy,
                isSaveEnabled: false,
                dialogTitle: "Add Deal Issue",
                status: null
            });

            this.props.onCancelClick(event);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    getSalesDetails() {
        if (isNaN(parseInt(this.state.dealNumber)) || this.state.selectedStore === null) {
            this.setState({
                salesDetails: null
            }, this.setData);
        }
        else {
            let inputData = {
                storeNumber: parseInt(this.state.selectedStore.store_number),
                dealNumber: parseInt(this.state.dealNumber)
            };
            this.props.applicationInsights.trackTrace({ message: "/DealIssues/getDashboardItems", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/DealIssues/GetSalesDetails", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({
                        salesDetails: parsedResponse[0]
                    }, this.setData);
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/DealIssues/GetSalesDetails", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
    }

    validateForm() {
        let isSaveEnabled = this.state.dealNumber !== ""
            && this.state.selectedStore !== null
            && this.state.stockNumber !== ""
            && this.state.vin !== ""
            && this.state.selectedType
            && this.state.issue !== "";

        this.setState({
            isSaveEnabled: isSaveEnabled
        });
    }

    setData() {
        if (!isNaN(parseInt(this.state.dealNumber)) && this.state.salesDetails) {
            this.setState({
                stockNumber: this.state.salesDetails.stock_number,
                customerName: this.state.salesDetails.customer_name,
                vin: this.state.salesDetails.vin,
                make: this.state.salesDetails.make,
                year: this.state.salesDetails.year,
                model: this.state.salesDetails.model
            }, this.validateForm);
        } else {
            this.setState({
                stockNumber: "",
                customerName: "",
                vin: "",
                make: "",
                year: "",
                model: ""
            });
        }
    }

    render() {
        return <DGDGModalDialogComponent
            applicationInsights={this.props.applicationInsights}
            showDialog={this.props.showDialog}
            cssName="dgdg-deal-issues-add-dialog"
            title={this.state.dialogTitle}
            onCancelClick={this.onCancelClick}
        >
            <DGDGModalDialogHeaderButtonComponent>
                <button className="btn btn-primary" onClick={this.onSaveNewClick} disabled={!this.state.isSaveEnabled}>
                    Save & New  <i className="fas fa-save dgdg-icon" />
                </button>
                <button className="btn btn-primary dgdg-icon" onClick={this.onSaveCloseClick} disabled={!this.state.isSaveEnabled}>
                    Save & Close  <i className="fas fa-save dgdg-icon" />
                </button>
            </DGDGModalDialogHeaderButtonComponent>
            <div className="dgdg-report-body">
                <div className="card">
                    <div className="card-block">
                        <table className="text-nowrap dgdg-deal-issues-table">
                            <tbody>
                                <tr>
                                    <td className="dgdg-font-blue">
                                        Store Name:<span className="text-danger">*</span>
                                    </td>
                                    <td>&nbsp;&nbsp;</td>
                                    <td>
                                        <DGDGFormDropdownComponent data={this.props.stores} disabled={this.props.rowData} itemKey="display_name" value={this.state.selectedStore ? this.state.selectedStore.display_name : "Select Store"} onItemClick={this.onStoreClick} />
                                    </td>
                                    <td>&nbsp;&nbsp;</td>
                                    <td className="dgdg-font-blue">
                                        Deal Number:<span className="text-danger">*</span>
                                    </td>
                                    <td />
                                    <td>
                                        <DGDGFormInputComponent id="dealNumber" value={this.state.dealNumber} disabled={this.props.rowData} onChange={this.onDealNumberChange} inputType="number" onLookupClick={this.onLookupClick} />
                                    </td>
                                    <td>&nbsp;&nbsp;</td>
                                    <td className="dgdg-font-blue">
                                        Type:<span className="text-danger">*</span>
                                    </td>
                                    <td />
                                    <td>
                                        <DGDGFormDropdownComponent data={this.typeList} itemKey="value" disabled={this.props.rowData} value={this.state.selectedType ? this.state.selectedType.value : "Select Type"} onItemClick={this.onTypeClick} />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="dgdg-font-blue">
                                        Customer Name:
                                    </td>
                                    <td />
                                    <td>
                                        <DGDGFormInputComponent id="customerName" value={this.state.customerName} onChange={this.onTextChange} disabled />
                                    </td>
                                    <td>&nbsp;&nbsp;</td>
                                    <td className="dgdg-font-blue">
                                        Stock #:
                                    </td>
                                    <td />
                                    <td>
                                        <DGDGFormInputComponent id="stockNumber" value={this.state.stockNumber} onChange={this.onTextChange} disabled />
                                    </td>
                                    <td>&nbsp;&nbsp;</td>
                                    <td className="dgdg-font-blue">
                                        VIN:
                                    </td>
                                    <td />
                                    <td>
                                        <DGDGFormInputComponent id="vin" value={this.state.vin} onChange={this.onTextChange} disabled />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="dgdg-font-blue">
                                        Make:
                                    </td>
                                    <td />
                                    <td>
                                        <DGDGFormInputComponent id="make" value={this.state.make} onChange={this.onTextChange} disabled />
                                    </td>

                                    <td>&nbsp;&nbsp;</td>
                                    <td className="dgdg-font-blue">
                                        Year:
                                    </td>
                                    <td />
                                    <td>
                                        <DGDGFormInputComponent id="year" value={this.state.year} onChange={this.onTextChange} disabled />
                                    </td>
                                    <td>&nbsp;&nbsp;</td>
                                    <td className="dgdg-font-blue">
                                        Model:
                                    </td>
                                    <td />
                                    <td>
                                        <DGDGFormInputComponent id="model" value={this.state.model} onChange={this.onTextChange} disabled />
                                    </td>

                                </tr>
                                <tr>
                                    <td className="dgdg-font-blue">
                                        Amount:
                                    </td>
                                    <td />
                                    <td>
                                        <DGDGFormInputComponent id="amount" value={this.state.amount} onChange={this.onTextChange} inputType="money" />
                                    </td>
                                    <td>&nbsp;&nbsp;</td>
                                    <td className="dgdg-font-blue">
                                        Last Updated By:
                                    </td>
                                    <td />
                                    <td>
                                        <DGDGFormInputComponent id="lastUpdatedBy" value={this.state.lastUpdatedBy} disabled={true} readOnly />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="dgdg-font-blue">
                                        Issue:<span className="text-danger">*</span>
                                    </td>
                                    <td />
                                    <td colSpan="5" className="dgdg-front-end-table-comment">
                                        <DGDGFormInputComponent id="issue" value={this.state.issue} onChange={this.onTextChange} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </DGDGModalDialogComponent>;
    }
}
