import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGFormInputComponent } from "../common/dgdginput/DGDGFormInputComponent";
import { DGDGModalDialogComponent } from "../common/dgdgmodaldialog/DGDGModalDialogComponent";
import { DGDGModalDialogHeaderButtonComponent } from "../common/dgdgmodaldialog/DGDGModalDialogHeaderButtonComponent";

export class OnboardingAddComponent extends React.Component {

    static getDerivedStateFromProps(props, state) {
        let newState = {};
        try {
            console.devLog("OnboardingAddComponent - getDerivedStateFromProps");
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
        return newState;
    }

    constructor(props) {
        super(props);
        this.state = {
            showSpinner: false,
            id: null,
            upn: "",
            password: "",
            dialogTitle: "Add User",
            isPasswordMasked: true
        };

        this.onTextChange = this.onTextChange.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onMaskClick = this.onMaskClick.bind(this);

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if (this.props.rowData !== null && this.props.rowData !== prevProps.rowData) {
                let dialogTitle = "Edit User - " + this.props.rowData.upn;
                this.setState({
                    upn: this.props.rowData.upn,
                    password: this.props.rowData.password,
                    dialogTitle: dialogTitle
                });
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onTextChange(id, value) {
        try {
            this.setState({
                [id]: value,
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onSaveClick(event) {
        try {
            this.props.onSaveClick(event, {
                id: this.state.id,
                upn: this.state.upn,
                password: this.state.password
            });
            this.onCancelClick(event);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onMaskClick(event, masked) {
        this.setState({ isPasswordMasked: !masked })
    }

    onKeyDown(event) {
        try {
            if (event.keyCode === 13) {
                this.onSaveClick();
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCancelClick(event) {
        try {
            this.setState({
                upn: "",
                password: "",
                isPasswordMasked: true,
                dialogTitle: "Add User"
            });

            this.props.onCancelClick(event);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGModalDialogComponent
            applicationInsights={this.props.applicationInsights}
            showDialog={this.props.showDialog}
            cssName="dgdg-onboarding-add-dialog"
            title={this.state.dialogTitle}
            onCancelClick={this.onCancelClick}
        >
            <DGDGModalDialogHeaderButtonComponent>
                <button className="btn btn-primary" onClick={this.onSaveClick}>
                    Save <i className="fas fa-save dgdg-icon" />
                </button>
            </DGDGModalDialogHeaderButtonComponent>
            <div className="dgdg-report-body">
                <div className="card">
                    <div className="card-block">
                        <table className="text-nowrap dgdg-onboarding-table">
                            <tbody>
                                <tr>
                                    <td className="dgdg-font-blue">
                                        UPN:
                                    </td>
                                    <td />
                                    <td>
                                        <DGDGFormInputComponent id="upn" value={this.state.upn} onChange={this.onTextChange} />
                                    </td>
                                    <td>&nbsp;&nbsp;</td>
                                    <td className="dgdg-font-blue">
                                        Password:
                                    </td>
                                    <td />
                                    <td>
                                        <DGDGFormInputComponent type={this.state.isPasswordMasked ? "password" : "text"} id="password" value={this.state.password} additionalData={this.state.password} onChange={this.onTextChange} onKeyDown={(event) => this.onKeyDown(event)} onMaskClick={this.onMaskClick} masked={this.state.isPasswordMasked} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </DGDGModalDialogComponent>;
    }
}
